import React, { useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { ITerminal } from './types';
import InputText from '~/components/Inputs/InputText';
import ToggleDefault from '~/components/ToggleDefault';
import { Col } from 'react-bootstrap';
import Loja from '~/components/Loja';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validation';

const CentralDeTerminal: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [lojas, setLojas] = useState<number | Array<number>>([]);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [formData, setFormData] = useState<ITerminal>({
    cod_loja: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    cod_terminal: {
      value: undefined,
      isInvalid: false,
      isRequired: false,
    },
    volume_hd: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    nome_pc: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    flg_op_balanca: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    flg_ativo: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
    flg_op_etiqueta: {
      value: false,
      isInvalid: false,
      isRequired: false,
    },
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const resetFormData = useCallback(() => {
    setFormData({
      ...formData,
      cod_loja: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      cod_terminal: {
        value: undefined,
        isInvalid: false,
        isRequired: false,
      },
      volume_hd: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      nome_pc: {
        value: '',
        isInvalid: false,
        isRequired: false,
      },
      flg_op_balanca: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      flg_ativo: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
      flg_op_etiqueta: {
        value: false,
        isInvalid: false,
        isRequired: false,
      },
    });
    reset();
    setUpdate(false);

    setInitInicializado(false);
  }, [formData]);

  useEffect(() => {
    if (iniInicializado) setInitInicializado(false);
  }, [formData]);

  const handleChangeTerminal = useCallback(
    (val: string) => {
      setFormData({
        ...formData,
        nome_pc: {
          ...formData.nome_pc,
          value: val.trimStart().toUpperCase(),
          isInvalid: val === '',
          isRequired: true,
        },
      });

      setValue('nome_pc', val.trimStart().toUpperCase());
    },
    [formData, setValue],
  );

  const handleChangeVolume = useCallback(
    (val: string, isInvalid: boolean) => {
      setFormData({
        ...formData,
        volume_hd: {
          ...formData.volume_hd,
          value: val.trimStart().trimEnd(),
          isInvalid,
        },
      });
      setValue('volume_hd', val.trimStart().trimEnd());
    },
    [formData, setValue],
  );

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_loja,
      cod_terminal,
      nome_pc,
      volume_hd,
      flg_ativo,
      flg_op_balanca,
      flg_op_etiqueta,
    } = row;

    setValue('volume_hd', volume_hd);
    setValue('nome_pc', nome_pc);
    setValue('flg_ativo', flg_ativo);
    setValue('flg_op_balanca', flg_op_balanca);
    setValue('flg_op_etiqueta', flg_op_etiqueta);

    setFormData({
      ...formData,
      cod_loja: {
        value: cod_loja,
        isInvalid: false,
        isRequired: false,
      },
      cod_terminal: {
        value: cod_terminal,
        isInvalid: false,
        isRequired: false,
      },
      nome_pc: {
        value: nome_pc,
        isInvalid: false,
        isRequired: false,
      },
      volume_hd: {
        value: volume_hd,
        isInvalid: false,
        isRequired: false,
      },
      flg_ativo: {
        value: flg_ativo,
        isInvalid: false,
        isRequired: false,
      },
      flg_op_balanca: {
        value: flg_op_balanca,
        isInvalid: false,
        isRequired: false,
      },
      flg_op_etiqueta: {
        value: flg_op_etiqueta,
        isInvalid: false,
        isRequired: false,
      },
    });

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm) => {
    let str = dataForm.nome_pc.replace(/[ ]/g, '<>');
    str = str.replace(/></g, '');
    str = str.replace(/<>/g, ' ');
    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/terminal/${formData?.cod_terminal.value}`,
          {
            lojas,
            cod_loja: formData.cod_loja.value,
            volume_hd: formData.volume_hd.value,
            nome_pc: str.trim(),
            flg_ativo: dataForm.flg_ativo,
            flg_op_balanca: dataForm.flg_op_balanca,
            flg_op_etiqueta: dataForm.flg_op_etiqueta,
          },
        );
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/terminal', {
        lojas,
        cod_loja: formData.cod_loja.value,
        cod_terminal: formData.cod_terminal.value,
        volume_hd: formData.volume_hd.value,
        nome_pc: dataForm.nome_pc,
        flg_ativo: dataForm.flg_ativo,
        flg_op_balanca: dataForm.flg_op_balanca,
        flg_op_etiqueta: dataForm.flg_op_etiqueta,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
        setShowSearch(true);
        return;
      }
    } finally {
      setLoader(false);
    }
  });

  const onNew = () => {
    resetFormData();
    setUpdate(true);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onlyReport
          onRowClick={onRowClick}
          codTela={206}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={206}
          title="CENTRAL DE LIBERAÇÃO DE TERMINAIS"
          codigoRegistro={[
            {
              value: formData.cod_terminal.value,
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={() => null}
          onClearFields={() => resetFormData()}
          isNew={false}
          isDelete={false}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <Container>
            <Col md={12} sm={12}>
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <Loja
                    selectedLoja={Number(formData.cod_loja.value)}
                    isDisabled={isUpdate}
                    onChange={(val) => {
                      setLojas(val);
                      setFormData({
                        ...formData,
                        cod_loja: { ...formData.cod_loja, value: Number(val) },
                      });
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-11">
                  <InputText
                    label="Nome do Terminal"
                    value={formData.nome_pc.value}
                    placeholder="Informe o Nome do Terminal"
                    isUndefined
                    isNull
                    isRequired
                    setInvalid={!!errors.nome_pc}
                    iniInicializado={!!errors.nome_pc}
                    onChange={(newValue: string) => {
                      handleChangeTerminal(newValue);
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-1">
                  <ToggleDefault
                    labelText="Ativo?"
                    setChecked={formData.flg_ativo.value}
                    onSwitch={() => {
                      setValue('flg_ativo', !formData.flg_ativo.value);

                      setFormData({
                        ...formData,
                        flg_ativo: {
                          ...formData.flg_ativo,
                          value: !formData.flg_ativo.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-8">
                  <InputText
                    label="Volume HD ou Identificador único de hardware"
                    value={formData.volume_hd.value}
                    placeholder=""
                    isUndefined
                    isDisabled={isUpdate}
                    isNull
                    isRequired={formData.volume_hd.isRequired}
                    setInvalid={formData.volume_hd.isInvalid}
                    iniInicializado={iniInicializado}
                    onChange={(newValue: string, isInvalid = true) => {
                      handleChangeVolume(newValue, isInvalid);
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-2">
                  <ToggleDefault
                    labelText="Opera Balança?"
                    setChecked={formData.flg_op_balanca.value}
                    onSwitch={() => {
                      setValue(
                        'flg_op_balanca',
                        !formData.flg_op_balanca.value,
                      );
                      setFormData({
                        ...formData,
                        flg_op_balanca: {
                          ...formData.flg_op_balanca,
                          value: !formData.flg_op_balanca.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-sm-12 col-md-2">
                  <ToggleDefault
                    labelText="Opera Etiqueta?"
                    setChecked={formData.flg_op_etiqueta.value}
                    onSwitch={() => {
                      setValue(
                        'flg_op_etiqueta',
                        !formData.flg_op_etiqueta.value,
                      );
                      setFormData({
                        ...formData,
                        flg_op_etiqueta: {
                          ...formData.flg_op_etiqueta,
                          value: !formData.flg_op_etiqueta.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Container>
        </FormDefault>
      )}
    </Container>
  );
};

export default CentralDeTerminal;
