import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  position: relative;
  .novo {
    align-items: center;
  }
  .nav-tabs {
    display: flex;
    margin: 10px;
  }

  .nav-link {
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 400;
    border-top-right-radius: 6px;
    border-radius: 6px 6px 0px 0px;

    button {
      background-color: ${({ theme: { colors } }) => colors.danger};
      border: none;
      color: ${({ theme: { colors } }) => colors.white};
      margin-left: 10px;
      opacity: 0;
      animation: opacity 0.35s ease-in-out;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 12px;
      }

      &:focus {
        outline: none;
      }
    }

    &:hover {
      button {
        opacity: 1;
      }
      background-color: ${({ theme: { colors } }) =>
        lighten(0.75, colors.primary)};
    }
    &.active {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.white};
      left: -1px;
    }
  }
  #tabUFContent {
    position: relative !important;
    display: flex !important;
    margin: 10px;
    width: 100% !important;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0px 6px 6px 6px;
  }
  .inpunts {
    z-index: 100;
  }
`;

export const LabelDoFildset = styled.div`
  display: flex;
  flex-direction: row;
  width: 180px;
  font-weight: 500;
`;

export const ContainerLoader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const GroupButton = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: flex-end;
  gap: 5px;
  padding: 0px;
`;
