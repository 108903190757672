import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useContext,
} from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { v4 } from 'uuid';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import api from '~/services/api';
import {
  Container,
  ButtonRow,
  Modal,
  ButtonRowPlus,
  ButtonRowCategoria,
} from './styles';

import InputSelect from '~/components/Inputs/InputSelect';

import { IOptions, ITipoCategoria } from './types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations/Validations';
import { InputText } from '~/components/NovosInputs';
import { ConfirmButton } from '~/components/Buttons';
import { MdDeleteForever } from 'react-icons/md';
import { GoPencil } from 'react-icons/go';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { BsPlusLg } from 'react-icons/bs';
import FooterDefault from '~/components/FooterDefault';
import defaultData from './validations/defaultData.json';
import { loadingContext } from '~/context/loading';
import { HotKeys, configure } from 'react-hotkeys';
import { useQueryClient } from 'react-query';

const MySwal = withReactContent(Swal);

const CategoriaFinanceira: React.FC = () => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const queryClient = useQueryClient();

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  const { loadingCategoriaFinanceira, setLoadingCategoriaFinanceira } =
    useContext(loadingContext);

  const [isUpdate, setUpdate] = useState(false);
  const [grid, setGrid] = useState<any[]>([]);
  const [selectCategorias, setSelectCategorias] = useState<any[]>([]);
  const [tipoInativo, setTipoInativo] = useState(false);
  const [tipoCategoria, setTipoCategoria] = useState<ITipoCategoria>({
    label: '',
    value: undefined,
  });
  const [show, setShow] = useState(false);
  const [nivel, setNivel] = useState(0);
  const [categoriaView, setCategoriaView] = useState('');
  const [init, setInit] = useState(false);

  const [tiposCategoriaSelect, setTiposCategoriaSelect] = useState([
    {
      label: 'Receitas',
      value: 0,
    },
    {
      label: 'Despesas',
      value: 1,
    },
    {
      label: 'Ambos',
      value: 2,
    },
  ]);

  const handleClose = () => {
    resetFormData();
    setShow(false);
  };

  useEffect(() => {
    (async () => {
      setLoadingCategoriaFinanceira(true);
      const { data } = await api.get(`/categoria/filhos`);

      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const options = data.data.map((categoria: any) => {
          return {
            id: categoria.cod_categoria,
            label: categoria.des_categoria,
            tipo_categoria: categoria.tipo_categoria,
          };
        });
        setSelectCategorias(options);
        setGrid(options);
        setNivel(0);
        setLoadingCategoriaFinanceira(false);
      }
    })();
  }, []);

  const resetFormData = useCallback(() => {
    setInit(false);
    const cod_categoria_pai = getValues('cod_categoria_pai');
    const tipo_categoria_pai = getValues('tipo_categoria_pai');
    reset(defaultData);
    setUpdate(false);

    if (nivel === 1) {
      setValue('cod_categoria_pai', cod_categoria_pai);
      setValue('tipo_categoria_pai', tipo_categoria_pai);
    }
    setTipoInativo(false);
  }, [reset, getValues, setValue, nivel]);

  const onChangeTipos = useCallback((nivelTipos: any) => {
    if (nivelTipos === 0) {
      return setTiposCategoriaSelect([
        {
          label: 'Receitas',
          value: 0,
        },
        {
          label: 'Despesas ',
          value: 1,
        },
        {
          label: 'Ambos',
          value: 2,
        },
      ]);
    }

    setTiposCategoriaSelect([
      {
        label: 'Receitas',
        value: 0,
      },
      {
        label: 'Despesas ',
        value: 1,
      },
    ]);
  }, []);

  const handleEditCategoria = useCallback(
    (isEdit?: boolean) => {
      if (isEdit) return setValue('categoriaEdit', true);

      setValue('categoriaEdit', false);
    },
    [setValue],
  );

  const handleChangeTipoCategoria = useCallback(
    (val: any, isEdit?: boolean) => {
      handleEditCategoria(isEdit);

      if (val !== undefined) {
        setTipoCategoria(val);
        return setValue('tipo_categoria', val.value);
      }

      setTipoCategoria({
        label: '',
        value: undefined,
      });
      setValue('tipo_categoria', '');
    },
    [setValue, handleEditCategoria],
  );

  const codPaiSelect = (nivelCategoria: any, categoriaId: any) => {
    if (nivelCategoria === 0) return null;

    return categoriaId;
  };

  const updateTab = useCallback((nivelCategoria: any, codPai: any) => {
    if (nivelCategoria === 0) {
      rowUpdate();
    } else {
      getSubCategorias(codPai);
    }
  }, []);

  const rowUpdate = async () => {
    setLoadingCategoriaFinanceira(true);
    const { data } = await api.get('/categoria/filhos');
    if (data.success && data.count > 0) {
      const options = data.data.map((categoria: any) => ({
        id: categoria.cod_categoria,
        label: categoria.des_categoria,
        tipo_categoria: categoria.tipo_categoria,
      }));
      setSelectCategorias(options);
      setGrid(options);
      setLoadingCategoriaFinanceira(false);
    }
  };

  const getSubCategorias = async (codCategoria: number) => {
    const cod_categoria_pai = getValues('cod_categoria_pai');
    setLoadingCategoriaFinanceira(true);
    const { data } = await api.get(`/categoria/filhos/${cod_categoria_pai}`);
    if (data.success && data.message !== 'Nenhum registro encontrado.') {
      const options = data.data.map((categoria: any) => {
        return {
          id: categoria.cod_categoria,
          label: categoria.des_categoria,
          tipo_categoria: categoria.tipo_categoria,
        };
      });
      setGrid(options);
      return setLoadingCategoriaFinanceira(false);
    }
    setGrid([]);
    setLoadingCategoriaFinanceira(false);
  };

  const getCategorias = async () => {
    if (selectCategorias.length > 0) {
      setGrid(selectCategorias);
    }
  };

  const onSave = handleSubmit(async (data) => {
    const codPai = codPaiSelect(nivel, data.cod_categoria_pai);
    try {
      if (isUpdate) {
        if (data.categoriaEdit || data.descricaoEdit) {
          setLoadingCategoriaFinanceira(true);

          const req = await api.put(`/categoria/${data.cod_categoria}`, {
            des_categoria: data.inputDescricao.trim(),
            num_nivel: nivel,
            cod_categoria_pai: codPai,
            tipo_categoria: data.tipo_categoria,
          });

          if (req.data.success) {
            toast.success(req.data.message);
            resetFormData();
            setShow(false);
            setValue('cod_categoria_pai', codPai);

            updateTab(nivel, codPai);
            return;
          }
          toast.warning(req.data.message);
        } else {
          setUpdate(false);
          setShow(false);
          resetFormData();
        }
      } else {
        setLoadingCategoriaFinanceira(true);
        const req = await api.post(`/categoria`, {
          des_categoria: data.inputDescricao.trim(),
          num_nivel: nivel,
          cod_categoria_pai: codPai || null,
          tipo_categoria: data.tipo_categoria || null,
        });
        if (req.data.success) {
          setShow(false);
          resetFormData();
          toast.success(req.data.message);
          setValue('cod_categoria_pai', codPai);
          updateTab(nivel, codPai);
        } else {
          toast.warning(req.data.message);
        }
      }
    } catch (error: any) {
      setLoadingCategoriaFinanceira(false);
      // Quando alterado a categoria e existe subcategoria incompatível
      if (error.status === 400) {
        if (
          error.data.message.includes(
            'Existem subcategorias com tipo incompatível',
          )
        ) {
          setShow(false);
          handleConflitoCategoria(
            data.cod_categoria,
            data.inputDescricao,
            nivel,
            codPai,
            data.tipo_categoria,
          );
          return;
        }
      }

      setLoadingCategoriaFinanceira(false);
    }
  });

  const handleShow = useCallback(() => {
    onChangeTipos(nivel);
    setShow(true);
  }, [nivel, onChangeTipos]);

  const criarCategoria = useCallback(() => {
    const tipoCategoriaPai = getValues('tipo_categoria_pai');

    if (nivel === 1 && tipoCategoriaPai !== 2) {
      setTipoInativo(true);
      const categoriaTipo = tiposCategoriaSelect.find((categoria: IOptions) => {
        return categoria.value === tipoCategoriaPai;
      });

      handleChangeTipoCategoria(categoriaTipo);
    } else {
      setTipoInativo(false);
      handleChangeTipoCategoria(undefined);
    }

    setUpdate(false);
    handleShow();
  }, [
    getValues,
    handleChangeTipoCategoria,
    handleShow,
    nivel,
    tiposCategoriaSelect,
  ]);

  const handleConflitoCategoria = useCallback(
    (
      cod_categoria_param,
      inputDescricao,
      nivelCategoria,
      codPai,
      tipo_categoria,
    ) => {
      MySwal.fire({
        title: `Deseja realmente alterar o tipo da categoria?`,
        text: `Essa alteração afetará todas as subcategorias! Deixando-as com o tipo que foi selecionada para a categoria que estão respectivamente inseridas`,
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await api.put(
            `/categoria/filhos/${cod_categoria_param}`,
            {
              des_categoria: inputDescricao,
              num_nivel: nivelCategoria,
              cod_categoria_pai: codPai,
              tipo_categoria,
            },
          );
          if (data.success) {
            setShow(false);
            resetFormData();
            toast.success(data.message);
            setValue('cod_categoria_pai', codPai);
            return updateTab(nivelCategoria, codPai);
          }
        }
      });
    },
    [resetFormData, updateTab, setValue],
  );

  const handleDelete = useCallback(
    (param: number, numNivel: number, categoriaId: number) => {
      let textTrue: string;
      let textError: string;
      let title;
      if (numNivel === 0) {
        title = 'Deseja excluir categoria?';
        textTrue = 'Categoria removida';
        textError =
          'Não foi possível remover a categoria, pois existem subcategorias relacionadas a ela';
      } else {
        title = 'Deseja excluir subcategoria?';
        textTrue = 'Subcategoria removida';
      }

      MySwal.fire({
        title: `${title}`,
        text: ``,
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await api.delete(`/categoria/${param}`);
          if (data.success) {
            setLoadingCategoriaFinanceira(true);
            setValue('cod_categoria_pai', categoriaId);
            updateTab(numNivel, categoriaId);
            setLoadingCategoriaFinanceira(false);
            toast.success(`${textTrue} com sucesso`);
          } else {
            MySwal.fire({
              text: `${textError}`,
              icon: 'warning',
              showConfirmButton: true,
            });
          }
        }
      });
    },
    [updateTab, setValue, setLoadingCategoriaFinanceira],
  );

  const onEditTab = (row: any) => {
    const tipoCategoriaPai = getValues('tipo_categoria_pai');
    if (nivel === 1 && tipoCategoriaPai !== 2) {
      setTipoInativo(true);
    } else {
      setTipoInativo(false);
    }

    const categoriaEditValue = tiposCategoriaSelect.filter(
      (categoria) => categoria.value === row.tipo_categoria,
    );

    setValue('inputDescricao', row.label.toUpperCase());
    setValue('tipo_categoria', categoriaEditValue[0]);
    setValue('cod_categoria', row.id);

    handleChangeTipoCategoria(categoriaEditValue[0]);
    setUpdate(true);
    handleShow();
  };

  const onEdit = (row: any) => {
    onEditTab(row);
  };
  const onDelete = (row: any) => {
    const cod_categoria_pai = getValues('cod_categoria_pai');
    handleDelete(row.id, nivel, cod_categoria_pai);
  };
  const onView = (row: any) => {
    setValue('tipo_categoria_pai', row.tipo_categoria);
    setValue('cod_categoria_pai', row.id);
    setCategoriaView(row.label);
    setNivel(nivel + 1);
    onChangeTipos(nivel + 1);
    getSubCategorias(row.id);
    handleChangeTipoCategoria(row.tipo_categoria);
  };

  const keyMap = {
    hkSalvar: 'ctrl+alt+s',
    hkCancelar: 'ctrl+alt+d',
  };

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === 34) {
        return true;
      }
    }

    return false;
  };

  const keyHandlers = {
    hkSalvar: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        setInit(true);
        onSave();
      }
    },
    hkCancelar: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        setInit(false);
        handleClose();
      }
    },
  };

  if (loadingCategoriaFinanceira) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <DefaultLoader />;
        </div>
      </Container>
    );
  }

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <Container>
        <h3
          style={{
            marginTop: '26px',
            marginLeft: '11px',
            marginBottom: '33px',
          }}
        >
          Categoria Financeira
        </h3>
        <hr
          style={{
            marginLeft: '10px',
            marginRight: '6px',
            marginBottom: '10px',
          }}
        />
        <div className="row" style={{ marginBottom: '10px' }}>
          <div
            className="row"
            style={{ marginLeft: '-8px', paddingRight: '14px' }}
          >
            <div
              className="col-sm-12"
              style={{
                paddingLeft: '18px',
                paddingRight: '0px',
              }}
            >
              <div style={{ marginTop: '20px;' }}>
                {nivel === 1 && (
                  <h5>Você está em: {categoriaView.toUpperCase()}</h5>
                )}
              </div>
              <div
                style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                className="col-sm-12 col-md-12 mt-1 dataTable"
              >
                <TableContainer key={v4()}>
                  <Table key={v4()} stickyHeader aria-label="sticky table">
                    <TableHead key={v4()}>
                      <TableRow key={v4()}>
                        {nivel !== 0 && (
                          <TableCell className="col-sm-12 col-md-1">
                            <FaAngleLeft
                              onClick={() => {
                                setNivel(nivel - 1);
                                getCategorias();
                                onChangeTipos(nivel - 1);
                                setValue('cod_cateogira_pai', '');
                              }}
                              title="Voltar para categorias "
                              size={25}
                              color="#fff"
                            />
                            <svg
                              className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              color="#fff"
                              style={{
                                maxWidth: '24px',
                                height: '24px',
                                color: '#fff',
                                position: 'relative',
                                right: '-28px',
                              }}
                            >
                              <path d="M11 19V5h2v14z" />
                            </svg>{' '}
                          </TableCell>
                        )}
                        <TableCell
                          className={`col-sm-12 ${
                            nivel !== 0 ? 'col-md-8' : 'col-md-9'
                          }`}
                        >
                          {nivel === 0 ? 'CATEGORIAS' : 'SUBCATEGORIAS'}
                        </TableCell>
                        <TableCell className="col-sm-12 col-md-3">
                          <svg
                            className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            color="#fff"
                            style={{
                              maxWidth: '24px',
                              height: '24px',
                              color: '#fff',
                              position: 'relative',
                              left: '-25px',
                            }}
                          >
                            <path d="M11 19V5h2v14z" />
                          </svg>{' '}
                          <ButtonRowPlus
                            type="button"
                            onClick={criarCategoria}
                            title="Criar categoria"
                            style={{ textAlign: 'right' }}
                          >
                            <BsPlusLg
                              title="Criar categoria "
                              size={25}
                              color="#fff"
                            />
                          </ButtonRowPlus>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <TableContainer key={v4()} style={{ height: '500px' }}>
                  <Table key={v4()}>
                    <TableBody>
                      {show && (
                        <Modal
                          animation={false}
                          size="lg"
                          show
                          onHide={handleClose}
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title>
                              <div className="row">
                                <p>
                                  {isUpdate
                                    ? `Editar ${
                                        nivel === 0
                                          ? 'Categoria'
                                          : 'Subcategoria'
                                      } (Código: ${getValues('cod_categoria')})`
                                    : `Cadastrar ${
                                        nivel === 0
                                          ? 'Categoria'
                                          : 'Subcategoria'
                                      }`}
                                </p>
                              </div>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              <div className="col-sm-12">
                                <InputText
                                  label="Descrição"
                                  maxLength={50}
                                  placeholder="Informe a descrição"
                                  name="inputDescricao"
                                  toLowerCase={false}
                                  register={register}
                                  control={control}
                                  setFocus={setFocus}
                                  disabled={false}
                                  isError={init && !!errors.inputDescricao}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    setValue(
                                      'inputDescricao',
                                      event.target.value.toUpperCase(),
                                    );
                                    setValue('descricaoEdit', true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-5">
                                <InputSelect
                                  options={tiposCategoriaSelect}
                                  label="Categoria"
                                  value={
                                    tiposCategoriaSelect.find((item: any) => {
                                      return item.value === tipoCategoria.value;
                                    }) ?? {
                                      value: undefined,
                                      label: '- Selecione o tipo -',
                                    }
                                  }
                                  placeholder="Selecione"
                                  isRequired={init && !!errors.tipo_categoria}
                                  setInvalid={init && !!errors.tipo_categoria}
                                  isDisabled={
                                    (nivel === 1 && tipoInativo) ||
                                    (nivel === 1 && isUpdate)
                                  }
                                  iniInicializado={
                                    init && !!errors.tipo_categoria
                                  }
                                  onChange={(selected: any) => {
                                    setInit(false);
                                    handleChangeTipoCategoria(selected, true);
                                  }}
                                />
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <ConfirmButton
                              onClick={() => {
                                setInit(false);
                                handleClose();
                              }}
                              style={{
                                color: '#ffffff',
                                background: '#dc3545',
                                height: '100%',
                                maxHeight: '42px',
                              }}
                              title="CTRL + ALT + D"
                            >
                              CANCELAR
                            </ConfirmButton>
                            <ConfirmButton
                              onClick={() => {
                                setInit(true);
                                onSave();
                              }}
                              style={{
                                color: '#ffffff',
                                background: '#28a745',
                                height: '100%',
                                maxHeight: '42px',
                              }}
                              title="CTRL + ALT + S"
                            >
                              SALVAR
                            </ConfirmButton>
                          </Modal.Footer>
                        </Modal>
                      )}
                      {grid.length === 0 && (
                        <TableRow key={v4()}>
                          <TableCell align="left"> </TableCell>
                          <TableCell align="center">
                            <div
                              className="gridEmpty"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p>Nenhum registro encontrado</p>
                            </div>
                          </TableCell>
                          <TableCell align="left"> </TableCell>
                        </TableRow>
                      )}
                      {grid.length > 0 &&
                        grid.map((row) => (
                          <TableRow key={v4()}>
                            {nivel !== 0 && (
                              <TableCell style={{ width: '80px' }} align="left">
                                {' '}
                              </TableCell>
                            )}
                            <TableCell
                              align="left"
                              style={{ width: '75%' }}
                              onClick={() => {
                                if (nivel === 0) onView(row);
                              }}
                            >
                              <strong>
                                <ButtonRowCategoria>
                                  {`${row.label.toUpperCase()} ${
                                    row.tipo_categoria === 0
                                      ? '(+)'
                                      : row.tipo_categoria === 1
                                      ? '(-)'
                                      : '(+/-)'
                                  }`}
                                </ButtonRowCategoria>
                              </strong>
                            </TableCell>
                            <TableCell
                              style={{ display: 'flex' }}
                              align="right"
                            >
                              {' '}
                              <>
                                {nivel === 0 && (
                                  <ButtonRow
                                    type="button"
                                    title="Visualizar"
                                    onClick={() => onView(row)}
                                  >
                                    <FaAngleRight
                                      size={30}
                                      style={{ color: '#3c7ae6' }}
                                    />
                                  </ButtonRow>
                                )}

                                <ButtonRow
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                  onClick={() => onEdit(row)}
                                  title="Editar"
                                >
                                  <GoPencil
                                    size={30}
                                    style={{ color: '#72ab90' }}
                                  />
                                </ButtonRow>
                                <ButtonRow
                                  type="button"
                                  onClick={() => onDelete(row)}
                                  title="Excluir"
                                >
                                  <MdDeleteForever
                                    size={30}
                                    style={{ color: '#e63c3c' }}
                                  />
                                </ButtonRow>
                              </>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
        <hr
          style={{
            marginLeft: '10px',
            marginRight: '6px',
            marginBottom: '10px',
          }}
        />
        <FooterDefault codTela={34} />
      </Container>
    </HotKeys>
  );
};

export default CategoriaFinanceira;
