import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  

  .super-app-theme--cell {
    text-align: center !important;
    align-items: center !important;
  }
  .actButtonsCredito {
    margin-top: 31px;
    display: flex;
    flex-direction: row;
  }
  .inputButton {
    z-index: 1;
    position: absolute;
    float: left;
    margin-left: -1px;
    height: 39px;
    line-height: 39px;
    border-radius: 3px 0px 0px 3px;
    background-color: ${({ theme: { colors } }) => colors.nav}!important;
  }
  .flags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    @media (min-width: 400px) {
      .flag:nth-child(2) {
        margin-left: 30px;
      }
    }
  }

  .MuiTableContainer-root{
    border: 1px solid rgba(224, 224, 224, 1) !important;
  }

  .MuiTable-root{
    border: 1px solid rgba(224, 224, 224, 1) !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
    background-color: transparent !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    right: -20px !important;
  }

  .MuiDataGrid-main {
    min-height: 430px !important;
  }
  .MuiIconButton-label {
    display: none !important;
  }

  .MuiDataGrid-footerContainer {
    display: none !important;
  }
  
  section.css-17e2yo9 {
    background-color: red !important;
  }
`;

export const Modal = styled(BootstrapModal)`
  .modal-lg {
    max-width: 50%;
  }
  .modal-content {
    border-radius: 4px;
    padding: 5px 40px;
    margin-top: 10px;
  }
  .modal-header {
    padding-left: 0px;
    padding-right: 0px;
    .modal-title {
      color: #57069e;
      font-size: 1.2rem;
      /* font-family: 'Nunito Sans'; */
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 0px;
    .row {
      margin-top: 20px;
    }
    .display-de-etiquetas {
      margin-top: 10px;
      padding: 0px 10px;
      overflow: auto;
      height: 550px;

      /* width */
      ::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #c7c7c7;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #ababab;

        transition: 0.2s;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #969696;
      }
    }
  }
  .modal-footer {
    padding: 15px 0px;
    border: none;
  }
`;

export const StyledForm = styled.form`
  padding: 20px;
  border-radius: 5px;
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const ButtonRowPlus = styled.button`
  flex: 1;
  border: none;
  background-color: transparent !important;
  position: relative;
  left: 66%;
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const ButtonRow = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  justify-content: space-between;
  font-weight: 400;
  // color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
`;

export const ButtonRowCategoria = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  justify-content: space-between;
  font-weight: 400;
  // color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
`;
