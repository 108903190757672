import React, { useContext, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { toast } from 'react-toastify';

import { ClipLoader } from 'react-spinners';

import { ThemeContext } from 'styled-components';

import Search from '~/components/Search';

import {
  Container,
  LabelDoFildset,
  ContainerLoader,
  GroupButton,
} from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import {
  InputPercent,
  InputText,
  InputMaskNcm,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';
import { transformAsCurrency } from '~/utils/functions';

const CatalogoNcm: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const formBlank = {
    des_ncm: '',
    num_ncm: '',
    per_aliq_est: '',
    per_aliq_imp: '',
    per_aliq_mun: '',
    per_aliq_nac: '',
  };

  const [loader, setLoader] = useState<boolean>(false);
  const [Invalid, setInvalid] = useState<boolean>(false);
  const { colors } = useContext(ThemeContext);

  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);

  const [codNcm, setCodNcm] = useState(0);

  const resetFormData = () => {
    setUpdate(false);
    setInvalid(false);
    reset();
    reset(formBlank);
  };

  const onRowClick = async (param: GridRowParams) => {
    setShowSearch(false);
    setLoader(true);
    setUpdate(true);
    const { row } = param;
    const {
      des_ncm,
      num_ncm,
      per_aliq_nac,
      per_aliq_imp,
      per_aliq_mun,
      per_aliq_est,
    } = row;
    setCodNcm(row.cod_ncm);
    setValue('des_ncm', des_ncm);
    setValue('num_ncm', num_ncm);
    setValue('per_aliq_nac', String(per_aliq_nac.toFixed(2)).replace('.', ','));
    setValue('per_aliq_imp', String(per_aliq_imp.toFixed(2)).replace('.', ','));
    setValue('per_aliq_mun', String(per_aliq_mun.toFixed(2)).replace('.', ','));
    setValue('per_aliq_est', String(per_aliq_est.toFixed(2)).replace('.', ','));

    setUpdate(true);
    setLoader(false);
  };

  const onCancel = () => {
    resetFormData();
    setShowSearch(true);
  };

  const onDelete = async () => {
    setLoader(true);

    try {
      const { data } = await api.delete(`/catalogo-ncm/${codNcm}`);

      if (data.success) {
        resetFormData();
        setShowSearch(true);
        toast.success(data.message);
      }
    } catch (e: any) {
      toast.warning(e.data.message);
    }
    setLoader(false);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  const onSave = handleSubmit(async (data1) => {
    try {
      const lastDigitNcm = String(data1.num_ncm).length - 1;
      const numNcmWithoutFormat = data1.num_ncm.replace(/\./g, '');
      if (String(data1.num_ncm)[lastDigitNcm] !== '_') {
        setInvalid(false);
      } else {
        return setInvalid(true);
      }
      if (isUpdate) {
        const res = await api.put(`/catalogo-ncm/${codNcm}`, {
          num_ncm: numNcmWithoutFormat,
          des_ncm: data1.des_ncm.trim(),
          per_aliq_nac: transformAsCurrency(data1.per_aliq_nac),
          per_aliq_imp: transformAsCurrency(data1.per_aliq_imp),
          per_aliq_est: transformAsCurrency(data1.per_aliq_est),
          per_aliq_mun: transformAsCurrency(data1.per_aliq_mun),
        });
        const { success, message } = res.data;

        if (!success) {
          return toast.warning(message);
        }

        if (success) {
          resetFormData();
          setShowSearch(true);
          return toast.success(message);
        }
      }
      const res = await api.post(`/catalogo-ncm`, {
        num_ncm: numNcmWithoutFormat,
        des_ncm: data1.des_ncm.trim(),
        per_aliq_nac: transformAsCurrency(data1.per_aliq_nac),
        per_aliq_imp: transformAsCurrency(data1.per_aliq_imp),
        per_aliq_est: transformAsCurrency(data1.per_aliq_est),
        per_aliq_mun: transformAsCurrency(data1.per_aliq_mun),
      });

      const { success, message } = res.data;
      if (success) {
        resetFormData();
        return toast.success(message);
      }
      setLoader(false);
      return toast.warning(message);
    } catch (e: any) {
      if (e.status === 409 || e.status === 404) {
        return toast.warning(e.data.message);
      }
      return toast.error(e.data.message);
    }
  });

  if (loader) {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={178}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={178}
          title="Catálogo de NCMs"
          codigoRegistro={[
            {
              value: codNcm,
              des_campo: 'Código',
            },
          ]}
          onCancel={onCancel}
          onClearFields={resetFormData}
          onDelete={onDelete}
          onNew={onNew}
          onReturnSearch={onCancel}
          onSave={onSave}
          isUpdate={isUpdate}
          deleteMessage="Deseja realmente excluir o NCM?"
        >
          <div className="row my-1">
            <div className="col-sm-12 col-md-2">
              <GroupButton>
                <InputMaskNcm
                  label="NCM"
                  name="num_ncm"
                  isError={!!errors.num_ncm || Invalid}
                  register={register}
                  control={control}
                />
              </GroupButton>
            </div>
            <div className="col-sm-12 col-md-10">
              <InputText
                label="Descrição"
                maxLength={100}
                placeholder="Informe a Descrição"
                name="des_ncm"
                register={register}
                control={control}
                disabled={false}
                isError={!!errors.des_ncm}
                caseInput="upper"
              />
            </div>
            <div className="col-sm-12 col-md-12 mt-2">
              <Separator labelText="Percentuais IBPT" />
              <div className="row">
                <LabelDoFildset>
                  <InputPercent
                    label="Aliq. Nac.:"
                    name="per_aliq_nac"
                    max={100}
                    min={0}
                    placeholder="0,00"
                    register={register}
                    control={control}
                    isError={!!errors.per_aliq_nac}
                    showIcon
                    step="2"
                  />
                </LabelDoFildset>
                <LabelDoFildset>
                  <InputPercent
                    label="Aliq. Imp.:"
                    name="per_aliq_imp"
                    max={100}
                    min={0}
                    placeholder="0,00"
                    register={register}
                    control={control}
                    isError={!!errors.per_aliq_imp}
                    showIcon
                  />
                </LabelDoFildset>
                <LabelDoFildset>
                  <InputPercent
                    label="Aliq. Est.:"
                    name="per_aliq_est"
                    max={100}
                    min={0}
                    placeholder="0,00"
                    register={register}
                    control={control}
                    isError={!!errors.per_aliq_est}
                    showIcon
                  />
                </LabelDoFildset>
                <div className="col-sm-12 col-md-2">
                  <LabelDoFildset>
                    <InputPercent
                      label="Aliq. Mun.:"
                      name="per_aliq_mun"
                      max={100}
                      min={0}
                      placeholder="0,00"
                      register={register}
                      control={control}
                      isError={!!errors.per_aliq_mun}
                      showIcon
                    />
                  </LabelDoFildset>
                </div>
              </div>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default CatalogoNcm;
